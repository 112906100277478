@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    display: flex;
    flex-direction: column;
    width: var(--card-width);
    height: var(--card-height);
    overflow: hidden;

    --card-width: 200px;
    --card-height: 320px;

    @media (max-width: $screen-size-sm) {
        --card-width: 160px;
        --card-height: 280px;
    }

    &:hover,
    &:focus-within {
        .footer {
            &::before {
                transform: translateY(0);
            }

            & * {
                color: var(--hover-text-color);
            }
        }
    }
}

.image {
    width: 100%;
    height: 168px;
    object-fit: contain;
}

.footer {
    position: relative;
    flex: 1;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--hover-bg-color);
        transition: all 0.2s cubic-bezier(1, 0, 0, 1);
        transform: translateY(101%);
    }

    & * {
        position: relative;
        z-index: 1;
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cover {
    position: relative;
}

.badge {
    position: absolute;
    max-width: 100%;

    &.primary {
        top: 0;
        right: 0;
    }

    &.secondary {
        right: 0;
        bottom: 0;
    }

    &.topList {
        top: 0;
        left: 0;
    }
}

.bookCardLink {
    text-decoration: none;

    &:focus,
    &:focus-within {
        outline: none;
    }
}
